export default {
  state: () => ({
    Pistols: [
      {
        type: "Легкий",
        name: "BudgetArms C-13",
        wa: -1,
        con: "P",
        avail: "E",
        damage: "1d6 (5mm)",
        shots: 8,
        rof: 2,
        rel: "ST",
        range: 50,
        price: 75,
      },
      {
        type: "Легкий",
        name: "Dai Lung Cybermag 15",
        wa: -1,
        con: "P",
        avail: "С",
        damage: "1d6+1 (6mm)",
        shots: 10,
        rof: 2,
        rel: "UR",
        range: 50,
        price: 50,
      },
      {
        type: "Легкий",
        name: "Federated Arms X-22",
        wa: 0,
        con: "P",
        avail: "E",
        damage: "1d6+1 (6mm)",
        shots: 10,
        rof: 2,
        rel: "ST",
        range: 50,
        price: 150,
      },
      {
        type: "Средний",
        name: "Militech Arms Avenger",
        wa: 0,
        con: "J",
        avail: "E",
        damage: "2d6+1 (9mm)",
        shots: 10,
        rof: 2,
        rel: "VR",
        range: 50,
        price: 250,
      },
      {
        type: "Средний",
        name: "Dai Lung Streetmast",
        wa: 0,
        con: "J",
        avail: "E",
        damage: "2d6+3 (10mm)",
        shots: 12,
        rof: 2,
        rel: "UR",
        range: 50,
        price: 250,
      },
      {
        type: "Средний",
        name: "Federated Arms X-9mm",
        wa: 0,
        con: "J",
        avail: "E",
        damage: "2d6+1 (9mm)",
        shots: 12,
        rof: 2,
        rel: "ST",
        range: 50,
        price: 300,
      },
      {
        type: "Тяжелый",
        name: "BudgetArms Auto 3",
        wa: -1,
        con: "J",
        avail: "E",
        damage: "3d6 (11mm)",
        shots: 8,
        rof: 2,
        rel: "UR",
        range: 50,
        price: 350,
      },
      {
        type: "Тяжелый",
        name: "Sternmeyer Type 35",
        wa: 0,
        con: "J",
        avail: "C",
        damage: "3d6 (11mm)",
        shots: 8,
        rof: 2,
        rel: "VR",
        range: 50,
        price: 400,
      },
      {
        type: "О.Тяжелый",
        name: "Armalite 44",
        wa: 0,
        con: "J",
        avail: "E",
        damage: "4d6+1 (12mm)",
        shots: 8,
        rof: 1,
        rel: "ST",
        range: 50,
        price: 450,
      },
      {
        type: "О.Тяжелый",
        name: "Colt AMT Model 2000",
        wa: 0,
        con: "J",
        avail: "C",
        damage: "4d6+1 (12mm)",
        shots: 8,
        rof: 1,
        rel: "VR",
        range: 50,
        price: 500,
      },
    ],
    Smg: [
      {
        type: "Легкий",
        name: "Uzi Miniauto 9",
        wa: +1,
        con: "J",
        avail: "E",
        damage: "2d6+1 (9mm)",
        shots: 30,
        rof: 35,
        rel: "VR",
        range: 150,
        price: 475,
      },
      {
        type: "Легкий",
        name: "H&K MP-2013",
        wa: +1,
        con: "J",
        avail: "C",
        damage: "2d6+3 (10mm)",
        shots: 35,
        rof: 32,
        rel: "ST",
        range: 150,
        price: 450,
      },
      {
        type: "Легкий",
        name: "Fed. Arms Tech Assault II",
        wa: +1,
        con: "J",
        avail: "C",
        damage: "1d6+1 (6mm)",
        shots: 50,
        rof: 25,
        rel: "ST",
        range: 150,
        price: 400,
      },
      {
        type: "Средний",
        name: "Arasaka Minami 10",
        wa: 0,
        con: "J",
        avail: "E",
        damage: "2d6+3 (10mm)",
        shots: 40,
        rof: 20,
        rel: "VR",
        range: 200,
        price: 500,
      },
      {
        type: "Средний",
        name: "H&K MPK-9",
        wa: +1,
        con: "J",
        avail: "C",
        damage: "2d6+1 (9mm)",
        shots: 35,
        rof: 25,
        rel: "ST",
        range: 200,
        price: 520,
      },
      {
        type: "Тяжелый",
        name: "Sternmeyer SMG 21",
        wa: -1,
        con: "L",
        avail: "E",
        damage: "3d6 (11mm)",
        shots: 30,
        rof: 15,
        rel: "VR",
        range: 200,
        price: 500,
      },
      {
        type: "Тяжелый",
        name: "H&K MPK-11",
        wa: 0,
        con: "L",
        avail: "C",
        damage: "4d6+1 (12mm)",
        shots: 30,
        rof: 20,
        rel: "ST",
        range: 200,
        price: 700,
      },
      {
        type: "Легкий",
        name: "Ingram MAC 14",
        wa: -2,
        con: "L",
        avail: "E",
        damage: "4d6+1 (12mm)",
        shots: 20,
        rof: 10,
        rel: "ST",
        range: 200,
        price: 650,
      },
    ],
    Rif: [
      {
        type: "RIF",
        name: "Militech Ronin Light Assault",
        wa: +1,
        con: "N",
        avail: "C",
        damage: "5d6 (5.56mm)",
        shots: 35,
        rof: 30,
        rel: "VR",
        range: 400,
        price: 450,
      },
      {
        type: "RIF",
        name: "AKR-20 Mefium Assault",
        wa: 0,
        con: "N",
        avail: "C",
        damage: "5d6 (5.56mm)",
        shots: 30,
        rof: 30,
        rel: "ST",
        range: 400,
        price: 500,
      },
      {
        type: "RIF",
        name: "FN-RAL Heavy Assault Rifle",
        wa: -1,
        con: "N",
        avail: "C",
        damage: "6d6+2 (7.62mm)",
        shots: 30,
        rof: 30,
        rel: "VR",
        range: 400,
        price: 600,
      },
      {
        type: "RIF",
        name: "Kalashnikov A-80 Heavy Rifle",
        wa: -1,
        con: "N",
        avail: "E",
        damage: "6d6+2 (7.62mm)",
        shots: 35,
        rof: 25,
        rel: "ST",
        range: 400,
        price: 550,
      },
    ],
    Sht: [
      {
        type: "SHT",
        name: "Arasaka Rapid Assault 12",
        wa: -1,
        con: "N",
        avail: "C",
        damage: "4d6 (00)",
        shots: 20,
        rof: 10,
        rel: "ST",
        range: 50,
        price: 900,
      },
      {
        type: "SHT",
        name: "Sternmeyer Stakeout 10",
        wa: -2,
        con: "N",
        avail: "R",
        damage: "4d6+2",
        shots: 10,
        rof: 2,
        rel: "ST",
        range: 50,
        price: 450,
      },
    ],
    Hvy: [
      {
        type: "HVY",
        name: "Barrett-Arasaka Light 20mm",
        wa: 0,
        con: "N",
        avail: "R",
        damage: "4d10 (AP 20/9)",
        shots: 10,
        rof: 1,
        rel: "VR",
        range: 450,
        price: 2000,
      },
      {
        type: "HVY",
        name: "Scorpion 16 Missile Launcher",
        wa: -1,
        con: "N",
        avail: "R",
        damage: "7d10",
        shots: 1,
        rof: 1,
        rel: "VR",
        range: 1000,
        price: 3000,
      },
      {
        type: "HVY",
        name: "Militech Arms RPG-A",
        wa: -2,
        con: "N",
        avail: "R",
        damage: "6d10",
        shots: 1,
        rof: 1,
        rel: "VR",
        range: 750,
        price: 1500,
      },
      {
        type: "HVY",
        name: "Граната",
        wa: 0,
        con: "P",
        avail: "P",
        damage: "от типа",
        shots: 1,
        rof: 1,
        rel: "VR",
        range: "Бросок",
        price: 30,
      },
      {
        type: "HVY",
        name: "C-6 Пластиковая взрывчатка",
        wa: 0,
        con: "P",
        avail: "P",
        damage: "8d10 на 1кг",
        shots: 1,
        rof: 1,
        rel: "VR",
        range: "нет",
        price: "100/1кг",
      },
      {
        type: "HVY",
        name: "Мина (все типы)",
        wa: 0,
        con: "J",
        avail: "P",
        damage: "4d10",
        shots: 1,
        rof: 1,
        rel: "VR",
        range: "нет",
        price: 350,
      },
      {
        type: "HVY",
        name: "Огнемёт K-A F-253",
        wa: -2,
        con: "N",
        avail: "R",
        damage: "2d10+1",
        shots: 0,
        rof: 1,
        rel: "ST",
        range: 50,
        price: 1500,
      },
    ],
    Meele: [
      {
        type: "Meele",
        name: "Дубинка",
        wa: 0,
        con: "L",
        avail: "C",
        damage: "1d6",
        shots: 0,
        rof: 0,
        rel: "-",
        range: 1,
        price: 0,
      },
      {
        type: "Meele",
        name: "Нож",
        wa: 0,
        con: "P",
        avail: "C",
        damage: "1d6",
        shots: 0,
        rof: 0,
        rel: "-",
        range: 1,
        price: "1-20",
      },
      {
        type: "Meele",
        name: "Меч",
        wa: 0,
        con: "N",
        avail: "C",
        damage: "2d6+2",
        shots: 0,
        rof: 0,
        rel: "-",
        range: 1,
        price: "2-200",
      },
      {
        type: "Meele",
        name: "Топор",
        wa: -1,
        con: "N",
        avail: "C",
        damage: "2d6+3",
        shots: 0,
        rof: 0,
        rel: "-",
        range: 1,
        price: 20,
      },
      {
        type: "Meele",
        name: "Нунчаки/Тонфа",
        wa: 0,
        con: "L",
        avail: "C",
        damage: "3d6",
        shots: 0,
        rof: 0,
        rel: "-",
        range: 1,
        price: 15,
      },
      {
        type: "Meele",
        name: "Нагината",
        wa: 0,
        con: "N",
        avail: "P",
        damage: "3d6",
        shots: 0,
        rof: 0,
        rel: "-",
        range: 1,
        price: 100,
      },
      {
        type: "Meele",
        name: "Сюрикэн",
        wa: 0,
        con: "P",
        avail: "C",
        damage: "1d6/3",
        shots: 0,
        rof: 0,
        rel: "-",
        range: "бросок",
        price: 2,
      },
      {
        type: "Meele",
        name: "Складной нож",
        wa: 0,
        con: "P",
        avail: "C",
        damage: "1d6/2",
        shots: 0,
        rof: 0,
        rel: "-",
        range: 1,
        price: 15,
      },
      {
        type: "Meele",
        name: "Латунный кастет",
        wa: 0,
        con: "P",
        avail: "C",
        damage: "1d6+2",
        shots: 0,
        rof: 0,
        rel: "-",
        range: 1,
        price: 10,
      },
      {
        type: "Meele",
        name: "Кувалда",
        wa: -1,
        con: "N",
        avail: "C",
        damage: "4d6",
        shots: 0,
        rof: 0,
        rel: "-",
        range: 1,
        price: 20,
      },
      {
        type: "Meele",
        name: "Бензопила",
        wa: -3,
        con: "N",
        avail: "C",
        damage: "4d6",
        shots: 0,
        rof: 0,
        rel: "-",
        range: 2,
        price: 80,
      },
    ],
    Exotic: [
      {
        type: "Экз",
        name: "Techtronica 15 Microwaver",
        wa: 0,
        con: "J",
        avail: "P",
        damage: "1d6 (м.волн)",
        shots: 10,
        rof: 2,
        rel: "VR",
        range: 20,
        price: 400,
      },
      {
        type: "Экз",
        name: "Militech Elect. LaserCannon",
        wa: 0,
        con: "N",
        avail: "R",
        damage: "1-5d6",
        shots: 10,
        rof: 2,
        rel: "UR",
        range: 200,
        price: 8000,
      },
      {
        type: "Экз",
        name: "Avante P-1135 Needlegun",
        wa: 0,
        con: "P",
        avail: "P",
        damage: "нарко",
        shots: 15,
        rof: 2,
        rel: "ST",
        range: 40,
        price: 200,
      },
      {
        type: "Экз",
        name: "Enertex AKM Power Squirt",
        wa: -2,
        con: "J",
        avail: "C",
        damage: "нарко",
        shots: 50,
        rof: 1,
        rel: "VR",
        range: 10,
        price: 15,
      },
      {
        type: "Экз",
        name: "Nelspot “Wombat” Airpistol",
        wa: -1,
        con: "J",
        avail: "C",
        damage: "нарко",
        shots: 20,
        rof: 2,
        rel: "UR",
        range: 40,
        price: 200,
      },
      {
        type: "Экз",
        name: "Militech Electronics Taser",
        wa: -1,
        con: "J",
        avail: "C",
        damage: "оглуш",
        shots: 10,
        rof: 1,
        rel: "ST",
        range: 10,
        price: 60,
      },
      {
        type: "Экз",
        name: "EagleTech “Tomcat” C-Bow",
        wa: 0,
        con: "N",
        avail: "C",
        damage: "4d6",
        shots: 12,
        rof: 1,
        rel: "VR",
        range: 150,
        price: 150,
      },
      {
        type: "Экз",
        name: "EagleTech “Stryker” X-Bow",
        wa: -1,
        con: "N",
        avail: "C",
        damage: "3d6+3",
        shots: 12,
        rof: 1,
        rel: "VR",
        range: 50,
        price: 220,
      },
      {
        type: "Экз Мили",
        name: "Kendachi Monoknife®",
        wa: 1,
        con: "P",
        avail: "P",
        damage: "2d6",
        shots: 0,
        rof: 0,
        rel: "VR",
        range: 1,
        price: 200,
      },
      {
        type: "Экз Мили",
        name: "Kendachi Monokatana®",
        wa: 1,
        con: "N",
        avail: "R",
        damage: "6d6",
        shots: 0,
        rof: 0,
        rel: "VR",
        range: 1,
        price: 600,
      },
      {
        type: "Экз Мили",
        name: "SPM-1 Battleglove™",
        wa: -2,
        con: "N",
        avail: "P",
        damage: "3d6/2d6",
        shots: 0,
        rof: 0,
        rel: "VR",
        range: 1,
        price: 900,
      },
    ],
    Ammo: [
      {
        name: "Легкий пистолет, Легкий ПП",
        amount: 100,
        price: 15,
      },
      {
        name: "Средний пистолет, ПП",
        amount: 50,
        price: 15,
      },
      {
        name: "Тяжелый пистолет, тяж. ПП",
        amount: 50,
        price: 15,
      },
      {
        name: "Очень тяжелый пистолет",
        amount: 50,
        price: 20,
      },
      {
        name: "Штурмовая винтовка",
        amount: 100,
        price: 40,
      },
      {
        name: "Дробовик",
        amount: 12,
        price: 15,
      },
      {
        name: "20мм пушка",
        amount: 1,
        price: 25,
      },
      {
        name: "Стрелы",
        amount: 12,
        price: 24,
      },
      {
        name: "Арбалетные болты",
        amount: 12,
        price: 30,
      },
      {
        name: "Гранулы для пневмо***",
        amount: 100,
        price: 6,
      },
      {
        name: "Игломет",
        amount: 50,
        price: 25,
      },
      {
        name: "Огнемет",
        amount: "комплект",
        price: 50,
      },
      {
        name: "Микро-гранатомет",
        amount: 4,
        price: 100,
      },
      {
        name: "Глушитель",
        amount: 1,
        price: 100,
      },
      {
        name: "Кобура (любая)",
        amount: 1,
        price: 20,
      },
      {
        name: "Оружейный ремень",
        amount: 1,
        price: 5,
      },
    ],
  }),
};
