export default {
  state: () => ({
    Look: [
      {
        type: "Уход за собой",
        price: 100,
      },
      {
        type: "Гардероб и стиль",
        price: 100,
      },
    ],
    Body: [
      {
        type: "Плавание",
        price: 100,
      },
    ],
    Int: [
      {
        type: "Бухгалтерия",
        price: 150,
      },
      {
        type: "Антропология",
        price: 150,
      },
      {
        type: "Биология",
        price: 150,
      },
      {
        type: "Ботаника",
        price: 150,
      },
      {
        type: "Химия",
        price: 150,
      },
      {
        type: "Образование и общие знания",
        price: 200,
      },
      {
        type: "Геология",
        price: 150,
      },
      {
        type: "История",
        price: 150,
      },
      {
        type: "Математика",
        price: 200,
      },
      {
        type: "Физика",
        price: 200,
      },
      {
        type: "Программирование ",
        price: 300,
      },
      {
        type: "Биржа",
        price: 300,
      },
      {
        type: "Выживание",
        price: 200,
      },
      {
        type: "Зоология",
        price: 150,
      },
      {
        type: "Напоминающий чип",
        price: 100,
      },
    ],
    Ref: [
      {
        type: "Стрельба из лука",
        price: 300,
      },
      {
        type: "Танцы",
        price: 150,
      },
      {
        type: "Вождение",
        price: 150,
      },
      {
        type: "Фехтование",
        price: 300,
      },
      {
        type: "Пистолеты",
        price: 300,
      },
      {
        type: "Тяжелое оружие",
        price: 400,
      },
      {
        type: "Ближний бой",
        price: 150,
      },
      {
        type: "Мотоциклы",
        price: 150,
      },
      {
        type: "Упр. тяжелой техникой",
        price: 200,
      },
      {
        type: "Пилот. вертолета",
        price: 300,
      },
      {
        type: "Пилот. с непод. крылом",
        price: 300,
      },
      {
        type: "Пилот. дирижабля",
        price: 300,
      },
      {
        type: "Пилот. с векторн. двигателем",
        price: 350,
      },
      {
        type: "Винтовки",
        price: 300,
      },
      {
        type: "Пистолет-пулемет",
        price: 300,
      },
    ],
    Tech: [
      {
        type: "Авиотехника",
        price: 250,
      },
      {
        type: "Ави",
        price: 300,
      },
      {
        type: "Базовые технологии",
        price: 200,
      },
      {
        type: "Знание крио-кам.",
        price: 150,
      },
      {
        type: "Конструирование кибердек",
        price: 200,
      },
      {
        type: "Кибер-технологии",
        price: 300,
      },
      {
        type: "Подрывное дело",
        price: 300,
      },
      {
        type: "Маскировка",
        price: 150,
      },
      {
        type: "Электроника",
        price: 150,
      },
      {
        type: "Электронная безопасность",
        price: 200,
      },
      {
        type: "Первая помощь",
        price: 150,
      },
      {
        type: "Подделка",
        price: 200,
      },
      {
        type: "Вертолеты",
        price: 300,
      },
      {
        type: "Фармацевтика",
        price: 200,
      },
      {
        type: "Взлом замков",
        price: 150,
      },
      {
        type: "Карманная кража",
        price: 150,
      },
      {
        type: "Игра на инструменте",
        price: 150,
      },
      {
        type: "Оружейник",
        price: 200,
      },
    ],
  }),
};
