export default {
  state: () => ({
    Standart: [
      {
        type: "Боевая перчатка",
        body: "Тяжелые рукавицы, покрывающие кисть и предплечье, сочленены с искусственными мышцами и гидравликой. Урона cжатием 3d6. Урон ударом 2d6. Имеет три разъема для любого стандартного оружия или опции для кибер-рук, кроме Гидравлических поршней",
        price: 900,
      },
      {
        type: "Умные очки",
        body: "Инструмент, позволяющий подключить до 4 опций кибер-оптики, без глазного импланта и без потери  человечности. Опции для очков стоят на 10% меньше, чем оригинал для кибер-оптики.",
        price: 200,
      },
      {
        type: "Линейная рама СИГМА",
        body: "Дает 12 Силы. Дебаф минус 2 Рефлекса, без подключения к нервной системе в качестве импланта",
        price: 5000,
      },
      {
        type: "Линейная рама БЕТА",
        body: "Дает 14 Силы. Дебаф минус 2 Рефлекса, без подключения к нервной системе в качестве импланта",
        price: 7000,
      },
      {
        type: "Линейная рама ОМЕГА",
        body: "Дает 16 Силы. Дебаф минус 2 Рефлекса, без подключения к нервной системе в качестве импланта",
        price: 9000,
      },
    ],
  }),
};
