export default {
  state: () => ({
    Armor: [
      {
        type: "Кожаные куртка и брюки *",
        body: "Руки, торc, ноги",
        sp: 0,
        obr: 0,
        price: "Разная",
      },
      {
        type: "Тяжелые кож. куртка, брюки",
        body: "Руки, торc, ноги",
        sp: 4,
        obr: 0,
        price: 50,
      },
      {
        type: "Кевларовая футболка, жилетка *",
        body: "Торс",
        sp: 10,
        obr: 0,
        price: 90,
      },
      {
        type: "Стальной шлем",
        body: "Голова",
        sp: 14,
        obr: 0,
        price: 20,
      },
      {
        type: "Нейлоновый шлем",
        body: "Голова",
        sp: 20,
        obr: 0,
        price: 100,
      },
      {
        type: "Легкая броне-куртка *",
        body: "Торc, руки",
        sp: 14,
        obr: 0,
        price: 150,
      },
      {
        type: "Средняя броне-куртка *",
        body: "Торс, руки",
        sp: 18,
        obr: 1,
        price: 200,
      },
      {
        type: "Бронированный жилет",
        body: "Торс",
        sp: 20,
        obr: 1,
        price: 200,
      },
      {
        type: "Бронированные брюки",
        body: "Ноги",
        sp: 20,
        obr: 1,
        price: 200,
      },
      {
        type: "Тяжелая броне-куртка *",
        body: "Торс, руки",
        sp: 20,
        obr: 2,
        price: 250,
      },
      {
        type: "Бронежилет борт-стрелка",
        body: "Торс",
        sp: 25,
        obr: 3,
        price: 250,
      },
      {
        type: "MetalGear™",
        body: "Все тело",
        sp: 25,
        obr: 2,
        price: 600,
      },
    ],
  }),
};
