export default {
  state: () => ({
    Tools: [
      {
        type: "Технический сканер",
        price: 600,
      },
      {
        type: "Газовый резак",
        price: 40,
      },
      {
        type: "Тех. инструментарий",
        price: 100,
      },
      {
        type: "Инструменты от ВэЕ",
        price: 120,
      },
      {
        type: "Инструментарий для электроники",
        price: 100,
      },
      {
        type: "Защитные очки",
        price: 20,
      },
      {
        type: "Фонарик",
        price: 2,
      },
      {
        type: "Химический источник света",
        price: 1,
      },
      {
        type: "Светящаяся краска",
        price: "10 за штуку",
      },
      {
        type: "Светящаяся лента",
        price: "32 за метр",
      },
      {
        type: "Веревка",
        price: "7 за метр",
      },
      {
        type: "Дыхательная маска",
        price: 30,
      },
    ],
    Electronic: [
      {
        type: "Голографический генератор",
        price: 500,
      },
      {
        type: "Видео экран",
        price: 100,
      },
      {
        type: "Чип данных",
        price: 10,
      },
      {
        type: "Лог компас",
        price: 50,
      },
      {
        type: "Цифровой диктофон",
        price: 300,
      },
      {
        type: "Цифровой фотоаппарат",
        price: 150,
      },
      {
        type: "Видеокамера",
        price: 800,
      },
      {
        type: "Видео/Аудио ленточный проигрыватель",
        price: 40,
      },
      {
        type: "Видеопленка",
        price: 4,
      },
      {
        type: "Карманный телевизор",
        price: 80,
      },
      {
        type: "Проигрыватель цифровых чипов",
        price: 150,
      },
      {
        type: "Цифровой муз. чип",
        price: 20,
      },
      {
        type: "Электрогитара",
        price: "100-500",
      },
      {
        type: "Эл. клавишные",
        price: "200-900",
      },
      {
        type: "Синтезатор ударных",
        price: "200-800",
      },
      {
        type: "Усилитель",
        price: "500-1000",
      },
    ],
    DataSys: [
      {
        type: "Портативный ПК",
        price: 900,
      },
      {
        type: "Карманный ПК",
        price: 100,
      },
      {
        type: "Кибер-модем",
        price: 0,
      },
      {
        type: "Сотовый кибер-модем",
        price: 0,
      },
      {
        type: "Интерфейсный кабель",
        price: "20-30",
      },
      {
        type: "Кабели низкого сопротив. (+1 к сопутст. задачам)",
        price: 60,
      },
      {
        type: "Комплект электродов (-2 к навыку Интерфейс)",
        price: 20,
      },
      {
        type: "Клавиатура",
        price: 100,
      },
      {
        type: "Терминал (-5 к Интерфейсу)",
        price: 400,
      },
    ],
    Connect: [
      {
        type: "Мастоид-коммуникатор",
        price: 100,
      },
      {
        type: "Карманный коммуникатор",
        price: 50,
      },
      {
        type: "Сотовый телефон",
        price: 400,
      },
      {
        type: "Мини сотовый телефон",
        price: 800,
      },
    ],
    View: [
      {
        type: "Очки-бинокли",
        price: 200,
      },
      {
        type: "Бинокль",
        price: 20,
      },
      {
        type: "Усиливающие свет очки",
        price: 200,
      },
      {
        type: "ИК очки",
        price: 250,
      },
      {
        type: "ИК лампа",
        price: 250,
      },
    ],
    Entertaiment: [
      {
        type: "Кинофильм",
        price: 10,
      },
      {
        type: "Аренда кассеты/чипа",
        price: 4,
      },
      {
        type: "Брейнданс",
        price: 20,
      },
      {
        type: "Живой концерт/Спортивное мероприятие",
        price: 50,
      },
      {
        type: "Еда быстрого пригот.",
        price: 5,
      },
      {
        type: "Хорошая выпивка",
        price: "3 (х1 х2 х3 за качество)",
      },
      {
        type: "Еда из ресторана",
        price: "20 (х1 х2 х3 за качество)",
      },
    ],
    Security: [
      {
        type: "Блокировка ключом",
        price: "20 за уровень",
      },
      {
        type: "Блокировка картой",
        price: "100 за уровень",
      },
      {
        type: "Блокировка голосом",
        price: "200 за уровень",
      },
      {
        type: "Перехватчик линии",
        price: 200,
      },
      {
        type: "Дешифратор кода",
        price: 500,
      },
      {
        type: "Дешифратор голоса",
        price: 1000,
      },
      {
        type: "Сканер безопасности",
        price: 1500,
      },
      {
        type: "Распознователь ядов",
        price: 1500,
      },
      {
        type: "Генератор помех",
        price: 500,
      },
      {
        type: "Сканирующая панель",
        price: 500,
      },
      {
        type: "Датчик движения",
        price: 40,
      },
      {
        type: "ID карта",
        price: 10,
      },
      {
        type: "Устройство отслеживания (дист. 1,6 км)",
        price: 1000,
      },
      {
        type: "Жучок слежения",
        price: 50,
      },
      {
        type: "Датчик дист. активации",
        price: 700,
      },
      {
        type: "Пласт-наручники",
        price: 100,
      },
      {
        type: "Стяжки (12 шт)",
        price: 5,
      },
    ],
    Med: [
      {
        type: "Степлер для кожи",
        price: 1000,
      },
      {
        type: "Спрей Кожа (антисептик, для ссадин)",
        price: "50 за банку",
      },
      {
        type: "Пластырь",
        price: "От препарата",
      },
      {
        type: "Кри-камера",
        price: 100000,
      },
      {
        type: "Аптечка",
        price: 50,
      },
      {
        type: "Хирургический комплект",
        price: 400,
      },
      {
        type: "Комплект первой помощи",
        price: 10,
      },
      {
        type: "Медицинский сканер (+2 к Диагностике болезней)",
        price: 300,
      },
      {
        type: "Анализатор наркотиков",
        price: 75,
      },
      {
        type: "Пневматический инъектор",
        price: 100,
      },
      {
        type: "Визит в клинику",
        price: 200,
      },
      {
        type: "День в госпитале",
        price: 300,
      },
      {
        type: "День в интенсивной терапии",
        price: 1000,
      },
      {
        type: "Замена конечности на клонированную",
        price: 1500,
      },
    ],
    Home: [
      {
        type: "Нейлоновая дорожная сумка",
        price: 5,
      },
      {
        type: "Спальный мешок",
        price: 25,
      },
      {
        type: "Надувной матрас",
        price: 25,
      },
      {
        type: "Футон",
        price: 90,
      },
      {
        type: "Мебель из натурального дерева",
        price: "200 за штуку",
      },
      {
        type: "Синтетическая мебель",
        price: "100 за штуку",
      },
      {
        type: "Квартира-Куб",
        price: 5000,
      },
      {
        type: "Лампа",
        price: 20,
      },
      {
        type: "Робот уборщик",
        price: 1000,
      },
      {
        type: "Голосовая система  выключателей",
        price: 100,
      },
    ],
    Vehicle: [
      {
        type: "Скутер",
        price: 500,
      },
      {
        type: "Мотоцикл",
        price: 1500,
      },
      {
        type: "Городской автомобиль",
        price: 2000,
      },
      {
        type: "Маленький субкомпакт",
        price: 6000,
      },
      {
        type: "Средний седан",
        price: 10000,
      },
      {
        type: "Спортивный автомобиль",
        price: 20000,
      },
      {
        type: "Роскошный седан",
        price: 40000,
      },
      {
        type: "Кибер-управление для любого авто",
        price: "х2 цена авто",
      },
    ],
    Lifestyle: [
      {
        type: "Услуги сотовой связи",
        price: "100 за месяц",
      },
      {
        type: "Обычное обсл связи",
        price: "30 за месяц",
      },
      {
        type: "Оплата телефонной связи",
        price: "0,5 за минуту",
      },
      {
        type: "Использование терминала данных",
        price: "1 за минуту",
      },
      {
        type: "Учетная запись кредитного чипа",
        price: "20 за месяц",
      },
      {
        type: "Медстраховка",
        price: "1000 за месяц",
      },
      {
        type: "Учетная запись ТраумаТим",
        price: "500 за месяц",
      },
      {
        type: "Воздух",
        price: "5 за минуту",
      },
      {
        type: "Маглев проездной",
        price: "0,25 за станцию",
      },
      {
        type: "Такси",
        price: "5,5 за км",
      },
      {
        type: "Ави-Такси",
        price: "15 за км",
      },
      {
        type: "Кабельное ТВ",
        price: "40 за месяц",
      },
    ],
    Food: [
      {
        type: "Корм",
        price: "50 за неделю",
      },
      {
        type: "Универсальная упаковка",
        price: "150 за неделю",
      },
      {
        type: "Хорошая упаковка",
        price: "200 за неделю",
      },
      {
        type: "Натуральная еда",
        price: "300 за неделю",
      },
    ],
    Appartment: [
      {
        type: "Гроб",
        price: "50 за ночь",
      },
      {
        type: "Номер отеля",
        price: "100 за ночь",
      },
      {
        type: "Аппартаменты/Квартира",
        price: "200 за месяц",
      },
      {
        type: "Дом",
        price: "150 за месяц",
      },
      {
        type: "В боевой зоне",
        price: "х1 цена",
      },
      {
        type: "Умеренная зона",
        price: "х2 цена",
      },
      {
        type: "Корпоративная зона",
        price: "х4 цена",
      },
      {
        type: "Зона для должностных лиц",
        price: "х6 цена",
      },
      {
        type: "Коммунальные услуги",
        price: "100 за месяц",
      },
    ]
  }),
};
